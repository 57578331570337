(<template>
  <div class="person"
       @mouseleave="cellFocusOver"
       @mouseover="cellFocus">
    <div :class="{'person__wrapper--birthday-week': isBirthDayWeek}"
         class="person__wrapper">
      <div class="email-phone">
        <div v-for="icon in icons"
             :key="icon.id"
             style="display: inline-flex;">
          <template v-if="icon.type === 'icon'">
            <span v-if="icon.hasValue"
                  :class="icon.classes"
                  @mouseleave="closeTooltip"
                  @mouseover="openTooltip(icon.hint, $event)"
                  @click="copyToClipboard(icon.hint)"></span>
            <span v-else
                  :class="icon.noValueClasses"></span>
          </template>
          <template v-if="icon.type === 'avatar'">
            <tt-avatar :avatar="icon.avatar"
                       class="person__avatar" />
          </template>
        </div>
      </div>
      <p class="person-name"
         @mouseleave="closeTooltip"
         @mouseover="openTooltip(nameCopyHint, $event)"
         @click="copyToClipboard(name || email)">{{ name || email }}</p>
      <button v-if="showAddBtn && addBtnVisibility"
              class="add-plus-btn--new person-add-skill__btn"
              @click="openCreateSkillModal"></button>
      <button v-if="showEditBtn"
              class="edit-pencil-btn--rounded person-edit__btn"
              @click="onEditClick"></button>
    </div>
  </div>
</template>)
<script>
  import CName from '@/components/grid/cells/shared/CName';
  import ToolTipMixin from '@/mixins/ToolTipMixin';

  export default {
    extends: CName,
    mixins: [ToolTipMixin],
    data() {
      return {
        addBtnVisibility: false
      };
    },
    computed: {
      icons() {
        return [
          {
            id: Math.random(),
            type: 'icon',
            classes: ['phone-icon'],
            noValueClasses: ['phone-icon--grey'],
            hint: this.phone,
            hasValue: this.phone
          },
          {
            id: Math.random(),
            type: 'icon',
            classes: ['alternative-phone-icon'],
            noValueClasses: ['alternative-phone-icon--grey'],
            hint: this.alternativePhone,
            hasValue: this.alternativePhone
          },
          {
            id: Math.random(),
            type: 'icon',
            classes: ['email-icon'],
            noValueClasses: ['email-icon---grey'],
            hint: this.email,
            hasValue: this.email
          },
          {
            id: Math.random(),
            type: 'avatar',
            avatar: this.avatarUrl
          }
        ];
      },
      alternativePhone() { return this.propData?.alternativePhone; },
      avatarUrl() { return this.propData?.avatarUrl; },
      anniversary() { return this.propData?.anniversary; },
      birthDate() { return this.$moment(this.anniversary?.birth).format('DD.MM.YYYY'); },
      nameCopyHint() {
        const temp = this.$gettext('%{name} <br> Click to copy');
        return this.$gettextInterpolate(temp, {name: this.name || this.email});
      },
      // birth day +/- 7 days from today
      isBirthDayWeek() { return this.anniversary?.birthAnniversary; },
      birthDayHintText() {
        const tamp = this.$gettext('Birthday <br> %{birthDate}');
        return this.$gettextInterpolate(tamp, {birthDate: this.birthDate});
      },
      showAddBtn() { return true; },
      showEditBtn() { return false; }
    },
    methods: {
      openCreateSkillModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'create-inter-skill-modal',
          classes: ['sk-modal--new', 'sk-modal--customer-actions'],
          width: 540,
          data: {
            title: this.$gettext('Add skill'),
            context: this,
            successHandlingCallbackName: 'refreshInterpretationSkillsGrid'
          }
        });
      },
      onEditClick() {},
      changeAddBtnVisibility(visibility) {
        this.addBtnVisibility = visibility;
      },
      cellFocusOver() {
        this.changeAddBtnVisibility(false);
        this.closeTooltip();
      },
      cellFocus(event) {
        this.changeAddBtnVisibility(true);
        if (this.isBirthDayWeek && this.birthDate && event.target.classList.contains('person__wrapper--birthday-week')) this.openTooltip(this.birthDayHintText, event);
      },
      refreshInterpretationSkillsGrid() {
        this.$store.commit('AllInterpretationSkillsStore/refreshInterpreterSkillGrid');
      }
    }
  };
</script>
<style scoped>
.person {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.person__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.person__wrapper--birthday-week {
  min-height: 50px;
  margin: 2px 0;
  padding: 6px 8px;
  border-radius: 8px;
  background-color: #f4f5f7;
  background-image: url(~@assets/imgs/undefined_imgs/birthday_cell_icon.svg), linear-gradient(to bottom right, #fff, #ededed);
  background-position: right bottom;
  background-repeat: no-repeat;
}

.person-name {
  overflow: hidden;
  font-weight: 600;
  text-overflow: ellipsis;
  cursor: pointer;
}

.email-phone {
  margin-right: 4px;
  margin-left: 0;
}

.person__avatar {
  width: 18px;
  min-width: 18px;
  height: 18px;
  margin-left: 8px;
}

.person-add-skill__btn {
  position: absolute;
  right: 10px;
  z-index: 10;
}

.person-edit__btn {
  margin-left: auto;
}
</style>
<style scoped src="@assets/css/phone_email_icon.css" />
