import helpers from '@/helpers';

export default {
  methods: {
    itemPosition(event) {
      const {top, left} = helpers.getElementPosition(event.target);
      return {top: (top) + 'px', left: (left + 20) + 'px'};
    },
    openTooltip(text, event, position = 'top-center') {
      this.$store.commit('TooltipRootStore/setTooltip', {
        data: {
          itemText: text,
          relativePosition: position
        },
        absolutePosition: this.itemPosition(event)
      });
    },
    closeTooltip() { this.$store.dispatch('TooltipRootStore/closeTooltip'); },
    copyToClipboard(value) { navigator.clipboard.writeText(value); }
  }
};
