<template>
  <div class="status-wrapper">
    <!------  Status Active  ------>
    <template v-if="status === 'active'">
      <div class="active-wrapper">
        <span class="tick-icon"
              @mouseleave="closeTooltip"
              @mouseover="openTooltip($gettext('Active'), $event)"></span>
        <div class="status-session-type__wrapper">
          <span :class="'phone-session--' + phoneSessionStatus"
                @mouseleave="closeTooltip"
                @mouseover="openTooltip(sessionStatusHint('Phone'), $event)"></span>
          <span :class="'video-session--' + videoSessionStatus"
                @mouseleave="closeTooltip"
                @mouseover="openTooltip(sessionStatusHint('Video'), $event)"></span>
          <span :class="'inperson-session--' + inpersonSessionStatus"
                @mouseleave="closeTooltip"
                @mouseover="openTooltip(sessionStatusHint('Inperson'), $event)"></span>
        </div>
      </div>
    </template>
    <!------  Status Paused  ------>

    <template v-if="status === 'paused'">
      <div class="paused-wrapper">
        <span class="paused-icon"
              @mouseleave="closeTooltip"
              @mouseover="openTooltip(pausedHoverText, $event)"></span>
        <p class="status__text">{{ $gettext('Paused') }}</p>
      </div>
      <p class="status-paused-date__text">{{ pausedTillDate }}</p>
    </template>

    <!------  Negative Statuses  ------>
    <template v-if="isNegativeStatus">
      <div class="blocked-wrapper">
        <span class="blocked-icon"
              @mouseleave="closeTooltip"
              @mouseover="openTooltip(accountStatusType, $event)"></span>
        <p class="status__text">{{ accountStatusType }}</p>
      </div>
    </template>

  </div>
</template>

<script>
  import ToolTipMixin from '@/mixins/ToolTipMixin';

  export default {
    mixins: [ToolTipMixin],
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      propData() { return this.params?.data ? this.params?.data : this.data; },
      accountStatus() { return this.propData?.accountStatus; },
      status() { return this.accountStatus?.name; },
      sessionTypesStatuses() { return this.propData?.sessionTypesStatuses; },
      phoneSession() { return this.sessionTypesStatuses?.phone; },
      videoSession() { return this.sessionTypesStatuses?.video; },
      inPersonSession() { return this.sessionTypesStatuses?.in_person; },
      videoRoomSession() { return this.sessionTypesStatuses?.videoroom; },
      pausedTillDate() { return this.$moment(this.accountStatus.expiresAt).format('DD.MM.YYYY, HH:mm'); },
      pausedComment() { return this.accountStatus?.comment || ''; },
      phoneSessionStatus() { return this.phoneSession?.name; },
      phoneSessionExpiresAt() { return this.phoneSession?.expiresAt; },
      videoSessionStatus() { return this.videoSession?.name; },
      videoSessionExpiresAt() { return this.videoSession?.expiresAt; },
      inpersonSessionStatus() { return this.inPersonSession?.name; },
      inpersonSessionExpiresAt() { return this.inPersonSession?.expiresAt; },
      pausedHoverText() {
        const temp = this.$gettext('Paused till %{pausedTillDate} <br> %{comment}');
        return this.$gettextInterpolate(temp, {pausedTillDate: this.pausedTillDate, comment: this.pausedComment});
      },
      accountStatusType() {
        switch (this.status) {
          case 'blocked':
            return this.$gettext('Blocked');
          case 'retired':
            return this.$gettext('Retired');
          case 'banned':
            return this.$gettext('Banned');
          case 'deleted':
            return this.$gettext('Deleted');
          case 'deceased':
            return this.$gettext('Deceased');
          case 'inactive':
            return this.$gettext('Inactive');
        }
      },
      isNegativeStatus() {
        const statuses = ['blocked', 'retired', 'banned', 'deleted', 'deceased', 'inactive'];
        return statuses.includes(this.status);
      }
    },
    methods: {
      sessionStatusHint(sessionType) {
        let sessionComputedStatus = '';
        let expiresAt = '';
        switch (sessionType) {
          case 'Phone':
            sessionComputedStatus = this.phoneSessionStatus;
            expiresAt = this.phoneSessionExpiresAt;
            break;
          case 'Video':
            sessionComputedStatus = this.videoSessionStatus;
            expiresAt = this.videoSessionExpiresAt;
            break;
          case 'Inperson':
            sessionComputedStatus = this.inpersonSessionStatus;
            expiresAt = this.inpersonSessionExpiresAt;
            break;
        }
        const pausedTemplate = expiresAt
          ? this.$gettext('Paused till %{expiresAt}')
          : this.$gettext('Paused');
        if (expiresAt) expiresAt = this.$moment(expiresAt).format('DD.MM.YYYY, HH:mm');

        switch (sessionComputedStatus) {
          case 'disabled':
            return this.$gettext('Disabled');
          case 'active':
            return this.$gettext('Active');
          case 'paused':
            return this.$gettextInterpolate(pausedTemplate, {expiresAt});
          case 'blocked':
            return this.$gettext('Blocked');
        }
      },
    }
  };
</script>

<style scoped>
.status-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  color: #555;
}

.blocked-wrapper,
.active-wrapper,
.paused-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.paused-wrapper {
  margin-bottom: 5px;
}

.tick-icon,
.paused-icon,
.phone-session--active,
.phone-session--paused,
.phone-session--blocked,
.phone-session--disabled,
.video-session--active,
.video-session--paused,
.video-session--blocked,
.inperson-session--active,
.inperson-session--paused,
.inperson-session--blocked,
.inperson-session--disabled,
.blocked-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  background-image: url(~@assets/imgs/undefined_imgs/tick_icon.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.blocked-icon {
  background-image: url(~@assets/imgs/undefined_imgs/close_x_icon_rounded.svg);
}

.paused-icon {
  background-image: url(~@assets/imgs/salita/hints/auto_invite/auto_invite_paused.svg);
}

.phone-session--active {
  background-image: url(~@assets/imgs/assignment_types/statuses/phone_active.svg);
}

.phone-session--paused {
  background-image: url(~@assets/imgs/assignment_types/statuses/phone_paused.svg);
}

.phone-session--blocked {
  background-image: url(~@assets/imgs/assignment_types/statuses/phone_blocked.svg);
}

.phone-session--disabled {
  background-image: url(~@assets/imgs/salita/job_grid/phone_grey_icon.svg);
}

.video-session--active {
  background-image: url(~@assets/imgs/assignment_types/statuses/video_active.svg);
}

.video-session--paused {
  background-image: url(~@assets/imgs/assignment_types/statuses/video_paused.svg);
}

.video-session--blocked {
  background-image: url(~@assets/imgs/assignment_types/statuses/video_blocked.svg);
}

.video-session--disabled {
  background-image: url(~@assets/imgs/salita/hints/session_type/video_preference_grey_no_reset.svg);
}

.inperson-session--active {
  background-image: url(~@assets/imgs/assignment_types/statuses/inperson_active.svg);
}

.inperson-session--paused {
  background-image: url(~@assets/imgs/assignment_types/statuses/inperson_paused.svg);
}

.inperson-session--blocked {
  background-image: url(~@assets/imgs/assignment_types/statuses/inperson_blocked.svg);
}

.inperson-session--disabled {
  background-image: url(~@assets/imgs/salita/hints/session_type/inperson_no_requirement_grey.svg);
}

.status-paused-date__text {
  font-size: 12px;
}

.status-session-type__wrapper {
  display: flex;
  padding: 4px;
  border-radius: 8px;
  background-color: #f4f5f7;
}
</style>
