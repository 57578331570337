// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/birthday_cell_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.person[data-v-612dea43] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  height: 100%;\n}\n.person__wrapper[data-v-612dea43] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  width: 100%;\n}\n.person__wrapper--birthday-week[data-v-612dea43] {\n  min-height: 50px;\n  margin: 2px 0;\n  padding: 6px 8px;\n  border-radius: 8px;\n  background-color: #f4f5f7;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "), -webkit-gradient(linear, left top, right bottom, from(#fff), to(#ededed));\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "), linear-gradient(to bottom right, #fff, #ededed);\n  background-position: right bottom;\n  background-repeat: no-repeat;\n}\n.person-name[data-v-612dea43] {\n  overflow: hidden;\n  font-weight: 600;\n  text-overflow: ellipsis;\n  cursor: pointer;\n}\n.email-phone[data-v-612dea43] {\n  margin-right: 4px;\n  margin-left: 0;\n}\n.person__avatar[data-v-612dea43] {\n  width: 18px;\n  min-width: 18px;\n  height: 18px;\n  margin-left: 8px;\n}\n.person-add-skill__btn[data-v-612dea43] {\n  position: absolute;\n  right: 10px;\n  z-index: 10;\n}\n.person-edit__btn[data-v-612dea43] {\n  margin-left: auto;\n}\n", ""]);
// Exports
module.exports = exports;
